/*
 * Copyright (C) 2019 - 2021 CONTACT Software GmbH
 * All rights reserved.
 * https://www.contact-software.com
 */

import React from 'react';
import LanguageSelector from './LanguageSelector';
import {getLogo, prefixNS, prefixThemeNS} from '../helpers';
import {formatStr} from '../i18n';

export default function LoginBox(props) {
    const {children} = props;
    return (
        <div className={prefixNS('loginbox') + ' ' + prefixThemeNS('loginbox')}>
            <div className={prefixNS('loginbox-flexbox')}>
                <div className={prefixNS('loginbox-flexbox__logo')}>
                    <img className='ce-logo' src={getLogo()} />
                </div>
                <div className={prefixNS('loginbox-flexbox__language')}>
                    <LanguageSelector />
                </div>
            </div>
            {children}
            <div
                className={prefixNS('copyright-notice')}
                dangerouslySetInnerHTML={{
                    __html: formatStr('copyright_notice'),
                }}
            />
        </div>
    );
}
