/*
 * Copyright (C) 2019 - 2021 CONTACT Software GmbH
 * All rights reserved.
 * https://www.contact-software.com
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Alert} from 'react-bootstrap';
import {postForm} from '../fetch';
import {formatStr} from '../i18n';
import {getComponentInitValue, getHiddenValues} from '../helpers';

const urlToMail = () =>
    'mailto:' +
    formatStr('onboarding_contact_email') +
    '?subject=' +
    encodeURIComponent(formatStr('onboarding_contact_subject')) +
    '&body=' +
    encodeURIComponent(formatStr('onboarding_contact_body'));

function formatMsg(msg) {
    if (!msg) {
        return [];
    }
    return msg.split(/\\n/);
}

function getMsgValue(data) {
    let messageClass = 'success';
    if (
        Object.prototype.hasOwnProperty.call(data, 'success') &&
        !data.success
    ) {
        messageClass = 'danger';
    }
    const retryPossible =
        Object.prototype.hasOwnProperty.call(data, 'retry_possible') &&
        data.retry_possible;
    const retriggerPossible = Object.prototype.hasOwnProperty.call(
        data,
        'retrigger_possible'
    )
        ? data.resend_possible
        : true;
    return {
        msgClass: messageClass,
        msg: formatMsg(data.msg),
        msgTitle: data.msg_title,
        retryPossible,
        retriggerPossible,
    };
}

export default function withForm(WrappedComponent) {
    return class FormSubmitter extends React.Component {
        constructor(props) {
            super(props);
            const initValue = getComponentInitValue();
            const msgValue = initValue.msg
                ? getMsgValue(initValue)
                : {
                      msgClass: '',
                      msg: [],
                      msgTitle: '',
                      retryPossible: true,
                      retriggerPossible: true,
                  };
            this.state = {
                ...msgValue,
                submitting: false,
            };
            this.doSubmit = this.doSubmit.bind(this);
        }

        doSubmit(url, data) {
            this.setState({
                submitting: true,
            });
            return postForm(url, data)
                .then((result) => {
                    if (result.redirect) {
                        window.location = result.redirect;
                    } else {
                        this.setState({
                            ...getMsgValue(result),
                            submitting: false,
                        });
                    }
                    return result;
                })
                .catch((error) => {
                    const response = error.response;
                    if (response) {
                        response
                            .json()
                            .then((result) => {
                                this.setState({
                                    ...getMsgValue(result),
                                    msgClass: 'danger',
                                    submitting: false,
                                });
                            })
                            .catch(() => {
                                this.setState({
                                    msgClass: 'danger',
                                    msg: formatMsg(
                                        formatStr('onboarding_internal_error')
                                    ),
                                    msgTitle: formatStr(
                                        'onboarding_internal_error_title'
                                    ),
                                    submitting: false,
                                    retryPossible: true,
                                    retriggerPossible: true,
                                });
                            });
                    } else {
                        this.setState({
                            msgClass: 'danger',
                            msg: formatMsg(error.toString()),
                            msgTitle: '',
                            submitting: false,
                            retryPossible: true,
                            retriggerPossible: true,
                        });
                    }
                });
        }

        render() {
            const {
                msg,
                msgClass,
                msgTitle,
                submitting,
                retryPossible,
                retriggerPossible,
            } = this.state;
            let messageBox = null;
            const hiddenValues = getHiddenValues();
            if (
                Object.prototype.hasOwnProperty.call(hiddenValues, 'query') &&
                hiddenValues.query.includes('invalid_request=true')
            ) {
                messageBox = (
                    <Alert bsStyle={'danger'}>
                        <strong>
                            {formatStr('onboarding_invalid_request_title')}
                        </strong>
                        <p>{formatStr('onboarding_invalid_request')}</p>
                        <p />
                        <a href={urlToMail()}>
                            {formatStr('onboarding_need_support')}
                        </a>
                    </Alert>
                );
            }

            if (msg.length || msgTitle) {
                messageBox = (
                    <React.Fragment>
                        {messageBox}
                        <Alert bsStyle={msgClass}>
                            <strong>{msgTitle}</strong>
                            {msg.map((text, idx) => (
                                <p key={idx}>{text}</p>
                            ))}
                            <p />
                            {msgClass === 'danger' ? (
                                <a href={urlToMail()}>
                                    {formatStr('onboarding_need_support')}
                                </a>
                            ) : null}
                        </Alert>
                    </React.Fragment>
                );
            }
            return (
                <WrappedComponent
                    doSubmit={this.doSubmit}
                    submitting={submitting}
                    messageBox={messageBox}
                    retryPossible={retryPossible}
                    retriggerPossible={retriggerPossible}
                />
            );
        }
    };
}

export const formPropTypes = {
    doSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    messageBox: PropTypes.element,
    retryPossible: PropTypes.bool,
    retriggerPossible: PropTypes.bool,
};
