/*
 * Copyright (C) 2019 - 2021 CONTACT Software GmbH
 * All rights reserved.
 * https://www.contact-software.com
 */

import React from 'react';
import ReactDOM from 'react-dom';
import Login from './components/Login';
import Registration from './components/Registration';
import RegistrationPassword from './components/RegistrationPassword';
import SMSValidation from './components/SMSValidation';
import ContinueToComplete from './components/ContinueToComplete';
import {initAppSetup, getAppComponentName} from './helpers';

export default function run() {
    initAppSetup();
    let App;
    switch (getAppComponentName()) {
        case 'Login':
            App = Login;
            break;
        case 'Registration':
            App = Registration;
            break;
        case 'SMSValidation':
            App = SMSValidation;
            break;
        case 'RegistrationPassword':
            App = RegistrationPassword;
            break;
        case 'ContinueToComplete':
            App = ContinueToComplete;
            break;
        default:
            App = Login;
    }
    ReactDOM.render(<App />, document.getElementById('appRoot'));
}
