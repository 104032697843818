/*
 * Copyright (C) 2019 - 2021 CONTACT Software GmbH
 * All rights reserved.
 * https://www.contact-software.com
 */

import React, {useCallback, useMemo, useState} from 'react';
import {
    ControlLabel,
    FormControl,
    FormGroup,
    Overlay,
    OverlayTrigger,
    Popover,
} from 'react-bootstrap';
import {
    getEmailToRegister,
    getFormAction,
    getHiddenValues,
    getPhoneMethods,
    getPhoneNumberRequired,
    getReturnTo,
    prefixNS,
} from '../helpers';
import {formatStr} from '../i18n';
import LoginBox from './LoginBox';
import withForm, {formPropTypes} from './WithForm';
import Button from './Button';
import Notice from './Notice';
import Throbber from './Throbber';
import {PhoneInputForm} from './PhoneInputForm';

function validateEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
}

function Registration(props) {
    const [email, setEmail] = useState(getEmailToRegister());
    const [phoneNumber, setPhoneNumber] = useState('');
    const [submittable, setSubmittable] = useState(true);
    const [success, setSuccess] = useState(false);
    // Use state instead of useRef because of a potential bug in react-bootstrap.
    // Target change doesn't lead to re-render of overlay
    const [inputRef, setInputRef] = useState(null);
    const requirePhoneNumber = getPhoneNumberRequired();
    const phoneMethods = getPhoneMethods();
    const phoneMethodKeys = useMemo(
        () => Object.keys(phoneMethods).sort(),
        [phoneMethods]
    );
    const [phoneMethod, setPhoneMethod] = useState(phoneMethodKeys[0]);

    const updateInputRef = useCallback(
        (node) => setInputRef(node),
        [setInputRef]
    );

    const {messageBox, submitting, doSubmit, retryPossible} = props;

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setSubmittable(true);
    };

    const onPhoneNumberChange = (newNumber) => {
        setPhoneNumber(newNumber);
        setSubmittable(true);
    };

    const handleBack = (event) => {
        event.preventDefault();
        const url = getReturnTo();
        if (url) {
            window.location = url;
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formURL = getFormAction();
        const data = {
            email,
            phone_number: phoneNumber,
            phone_method: phoneMethod,
            ...getHiddenValues(),
        };
        doSubmit(formURL, data).then(() => {
            setSuccess(true);
            setSubmittable(false);
            if (inputRef) {
                inputRef.focus();
            }
        });
    };

    const buttonGroupCls =
        'cs-web-components-base-button-group ' + prefixNS('button-group');
    const valid = validateEmail(email) && (phoneNumber || !requirePhoneNumber);
    const backURL = getReturnTo();
    const backButtonLabel =
        success && !retryPossible
            ? formatStr('onboarding_return_to_login_button')
            : formatStr('onboarding_cancel_button');
    const backButtonStyle = success && !retryPossible ? undefined : 'outline';

    const inputClass =
        success && !submittable ? prefixNS('input-invalid') : null;

    const hiddenValue = getHiddenValues();
    const action = hiddenValue ? hiddenValue.action : '';

    const popoverMessage =
        action === 'password_reset'
            ? formatStr('onboarding_reset_id_announcement')
            : formatStr('onboarding_use_id_announcement');

    const overlay = (
        <Overlay show={true} target={inputRef} shouldUpdatePosition={true}>
            <Popover id='popover-basic'>{popoverMessage}</Popover>
        </Overlay>
    );

    const submitButton =
        success && !retryPossible ? null : (
            <Button
                onClick={handleSubmit}
                submit={true}
                disabled={!valid || !submittable}
                value={formatStr('onboarding_continue_button')}
            />
        );

    const backButton = backURL ? (
        <Button
            onClick={handleBack}
            buttonStyle={backButtonStyle}
            value={backButtonLabel}
        />
    ) : null;

    const controls = submitting ? (
        <Throbber />
    ) : (
        <div className={buttonGroupCls}>
            {submitButton}
            {backButton}
        </div>
    );

    let content;
    if (messageBox && !retryPossible) {
        content = (
            <div className={prefixNS('msg-box')}>
                {messageBox}
                {controls}
            </div>
        );
    } else {
        const formClasses = `field-wrapper ${prefixNS('form-group')}`;
        content = (
            <form onSubmit={handleSubmit} className={prefixNS('form')}>
                {messageBox}
                <fieldset>
                    <FormGroup className={formClasses} controlId='email'>
                        <ControlLabel>
                            {formatStr('onboarding_username')}
                        </ControlLabel>
                        <OverlayTrigger
                            trigger='focus'
                            placement='right'
                            overlay={overlay}
                        >
                            <FormControl
                                disabled={success && !retryPossible}
                                className={inputClass}
                                inputRef={updateInputRef}
                                autoFocus
                                name='email'
                                type='email'
                                placeholder='domain@example.com'
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </OverlayTrigger>
                    </FormGroup>
                    <PhoneInputForm
                        formClasses={formClasses}
                        inputClass={inputClass}
                        phoneNumber={phoneNumber}
                        selectedMethod={phoneMethod}
                        selectMethod={setPhoneMethod}
                        disabled={success && !retryPossible}
                        setPhoneNumber={onPhoneNumberChange}
                    />
                    <Notice labelName={'agreement_notice'} />
                    {controls}
                </fieldset>
            </form>
        );
    }

    return <LoginBox>{content}</LoginBox>;
}

Registration.propTypes = formPropTypes;

export default withForm(Registration);
