/*
 * Copyright (C) 2019 - 2021 CONTACT Software GmbH
 * All rights reserved.
 * https://www.contact-software.com
 */

import React from 'react';
import {formatStr} from '../i18n';
import LoginBox from './LoginBox';
import {Alert} from 'react-bootstrap';
import Button from './Button';
import {getReturnTo, prefixThemeNS} from '../helpers';

export default function ContinueToComplete() {
    const handleSubmit = (event) => {
        event.preventDefault();
        window.location = getReturnTo();
    };
    return (
        <LoginBox>
            <div className={prefixThemeNS('logoutbox-container')}>
                <Alert bsStyle='success'>
                    <strong>
                        {formatStr('onboarding_registeration_not_finished')}
                    </strong>
                    <p>
                        {formatStr(
                            'onboarding_registeration_not_finished_text'
                        )}
                    </p>
                </Alert>
                <Button
                    onClick={handleSubmit}
                    submit={true}
                    value={formatStr('onboarding_continue_button')}
                />
            </div>
        </LoginBox>
    );
}
