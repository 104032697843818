/*
 * Copyright (C) 2019 - 2021 CONTACT Software GmbH
 * All rights reserved.
 * https://www.contact-software.com
 */

import React from 'react';
import {prefixNS} from '../helpers';

export default function Throbber() {
    const className = prefixNS('throbber');
    return <div className={className} />;
}
