/*
 * Copyright (C) 2019 - 2021 CONTACT Software GmbH
 * All rights reserved.
 * https://www.contact-software.com
 */

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';
import cookies from 'browser-cookies';
import {
    getCurrentLanguage,
    getFormAction,
    getHiddenValues,
    getLastUsername,
    getPasswordResetURL,
    getRegistrationURL,
    getRememberMe,
    prefixNS,
} from '../helpers';
import {formatStr} from '../i18n';
import withForm, {formPropTypes} from './WithForm';
import LoginBox from './LoginBox';
import Button from './Button';
import Throbber from './Throbber';
import {CDBPC_PASSWORD, CDBPC_USERNAME} from '../constants';

function Login({doSubmit, submitting, messageBox}) {
    const [refsParsed, setRefsParsed] = useState(false);
    const [loginUser, setLoginUser] = useState(
        getRememberMe() ? getLastUsername() : ''
    );
    const [loginPassword, setLoginPassword] = useState('');
    const [remember, setRemember] = useState(getRememberMe());
    const userElement = useRef(null);
    const passwordElement = useRef(null);

    if (!refsParsed && userElement.current && passwordElement.current) {
        // Upon first load take values from elements as the PC client
        // may have injected them without calling the respective handlers.
        setLoginUser(userElement.current.value);
        setLoginPassword(passwordElement.current.value);
        setRefsParsed(true);
    }

    const formValid = loginUser.length > 0;
    const registrationURL = getRegistrationURL();
    const passwordResetURL = getPasswordResetURL();

    const handleUser = useCallback(
        (e) => {
            setLoginUser(e.target.value);
        },
        [setLoginUser]
    );
    const handlePassword = useCallback(
        (e) => {
            setLoginPassword(e.target.value);
        },
        [setLoginPassword]
    );
    const handleSubmit = useCallback(
        (e) => {
            let username = loginUser;
            let password = loginPassword;
            if (e) {
                e.preventDefault();
            } else {
                // PC Client injected values, do not rely on state as the handlers were not called
                username = userElement.current.value;
                password = passwordElement.current.value;
                setLoginUser(username);
                setLoginPassword(password);
            }
            const formURL = getFormAction();
            const lang = getCurrentLanguage();
            const data = {
                username,
                password,
                remember,
                lang,
                ...getHiddenValues(),
            };
            doSubmit(formURL, data);
        },
        [loginUser, loginPassword, remember, doSubmit]
    );
    const handleToggle = useCallback(() => {
        const newRemember = !remember;
        if (newRemember) {
            cookies.erase('contact.dont_remember');
        } else {
            cookies.set('contact.dont_remember', '1', {expires: 104});
        }
        setRemember(newRemember);
    }, [remember, setRemember]);
    const handleCreate = useCallback(() => {
        if (registrationURL) {
            const data = {
                action: 'registration',
                email_to_register: btoa(loginUser),
                ...getHiddenValues(),
            };
            doSubmit(registrationURL, data);
        }
    }, [registrationURL, loginUser, doSubmit]);
    const handleReset = useCallback(() => {
        if (passwordResetURL) {
            const data = {
                action: 'password_reset',
                email_to_register: btoa(loginUser),
                ...getHiddenValues(),
            };
            doSubmit(passwordResetURL, data);
        }
    }, [passwordResetURL, loginUser, doSubmit]);

    // This ensures compatibility with cdbpc "--autologon".
    useEffect(() => {
        window.jQuery = window.$ = () => {
            return {
                ready: (fn) => {
                    if (fn.toString().indexOf('checkLoginReady') !== -1) {
                        handleSubmit(null);
                    } else {
                        fn();
                    }
                },
            };
        };
    }, [handleSubmit]);

    return (
        <LoginBox>
            <form onSubmit={handleSubmit} className={prefixNS('form')}>
                {messageBox}
                <fieldset>
                    <FormGroup
                        className='field-wrapper'
                        controlId={CDBPC_USERNAME}
                    >
                        <ControlLabel>
                            {formatStr('onboarding_username')}
                        </ControlLabel>
                        <FormControl
                            autoFocus
                            name='username'
                            type='text'
                            placeholder='domain@example.com'
                            inputRef={userElement}
                            disabled={submitting}
                            value={loginUser}
                            onChange={handleUser}
                        />
                    </FormGroup>
                    <FormGroup
                        className='field-wrapper password-wrapper'
                        controlId={CDBPC_PASSWORD}
                    >
                        <ControlLabel>
                            {formatStr('login_password_label')}
                        </ControlLabel>
                        <FormControl
                            value={loginPassword}
                            onChange={handlePassword}
                            disabled={submitting}
                            inputRef={passwordElement}
                            type='password'
                            name='password'
                        />
                    </FormGroup>
                    <FormGroup className='checkbox' controlId='remember'>
                        <input
                            name='remember'
                            type='checkbox'
                            checked={remember}
                            onChange={handleToggle}
                        />
                        <ControlLabel onClick={handleToggle}>
                            {formatStr('login_remember')}
                        </ControlLabel>
                    </FormGroup>

                    <div className={prefixNS('submit-container')}>
                        {submitting ? (
                            <Throbber />
                        ) : (
                            <Button
                                onClick={handleSubmit}
                                submit={true}
                                disabled={!formValid}
                                value={formatStr('login_button')}
                                className={prefixNS('login-btn')}
                            />
                        )}

                        {registrationURL && !submitting ? (
                            <Button
                                onClick={handleCreate}
                                buttonStyle='outline'
                                value={formatStr('onboarding_create_id')}
                                className={prefixNS('create-id-btn')}
                            />
                        ) : null}
                    </div>

                    {passwordResetURL && !submitting ? (
                        <p className={prefixNS('forgot-password')}>
                            <a onClick={handleReset} href='#'>
                                {formatStr('login_forgot_password')}
                            </a>
                        </p>
                    ) : null}
                </fieldset>
            </form>
        </LoginBox>
    );
}

Login.propTypes = formPropTypes;

export default withForm(Login);
