/*
 * Copyright (C) 2019 - 2021 CONTACT Software GmbH
 * All rights reserved.
 * https://www.contact-software.com
 */

import React from 'react';
import {
    Alert,
    ControlLabel,
    FormControl,
    FormGroup,
    InputGroup,
    Overlay,
    OverlayTrigger,
    Popover,
} from 'react-bootstrap';
import {
    getAllowBack,
    getFormAction,
    getFormData,
    getHiddenValues,
    getPasswordRules,
    getReturnTo,
    prefixNS,
} from '../helpers';
import {formatStr} from '../i18n';
import LoginBox from './LoginBox';
import withForm, {formPropTypes} from './WithForm';
import Notice from './Notice';
import Button from './Button';
import EyeCloseIcon from './cis_eye-close.svg';
import EyeOpenIcon from './cis_eye-open.svg';
import Throbber from './Throbber';

class RegistrationPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            showPassword: false,
            passwordViolationPopover: [],
            userCreationFailed: false,
            target: '',
            focused: false,
        };
        this.refPasswordInputGroup = React.createRef();
        this.refPasswordInput = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.passwordShowHide = this.passwordShowHide.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    validateForm() {
        return this.state.password !== '';
    }

    handleChange(event) {
        this.setState({
            password: event.target.value,
        });
    }

    handleBack(event) {
        event.preventDefault();
        const url = getReturnTo();
        if (url) {
            window.location = url;
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const formURL = getFormAction();
        const {password} = this.state;
        const form = getFormData();
        const data = {
            password,
            confirmation_token: form.confirmation_token,

            ...getHiddenValues(),
        };

        const promise = this.props.doSubmit(formURL, data);

        promise.then((result) => {
            if (result) {
                const {password_violation_popover, extra_data} = result;
                const userCreationFailed =
                    extra_data.user_creation_failed || false;
                this.setState(
                    {
                        passwordViolationPopover: password_violation_popover,
                        userCreationFailed: userCreationFailed,
                    },
                    () => {
                        if (this.refPasswordInput.current) {
                            this.refPasswordInput.current.focus();
                        }
                    }
                );
            }
        });
    }

    passwordShowHide(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState((state) => {
            return {
                showPassword: !state.showPassword,
            };
        });
    }

    onFocus(event) {
        const {onFocus} = this.props;
        this.setState({focused: true});
        if (onFocus) {
            onFocus(event);
        }
    }

    onBlur(event) {
        const {onBlur} = this.props;
        this.setState({focused: false});
        if (onBlur) {
            onBlur(event);
        }
    }

    render() {
        const valid = this.validateForm();
        const backURL = getReturnTo();
        const allowBack = getAllowBack();
        const hiddenValue = getHiddenValues();
        const action = hiddenValue ? hiddenValue.action : '';
        const {
            passwordViolationPopover,
            focused,
            password,
            showPassword,
            userCreationFailed,
        } = this.state;
        const {submitting} = this.props;
        const backButtonLabel = formatStr('onboarding_cancel_button');
        const violations = Array.from(passwordViolationPopover);
        let hintList = null;

        const form = getFormData();
        const formControlCls =
            'cs-web-components-base-form-input-default ' +
            'cs-web-components-base-form-input no-ie-text-input-clear-button';
        let formGroupClsNotSelected =
            'cs-web-components-base-formcontrols-formcontrol ' +
            'cs-web-components-base-formcontrols-withtypeahead form-group ';
        let formGroupClsSelected =
            'cs-web-components-base-formcontrols-formcontrol ' +
            'cs-web-components-base-formcontrols-withtypeahead ' +
            'cs-web-components-base-formcontrols-formcontrol-focused form-group ';

        if (violations.length > 0) {
            hintList = violations.map((vio, index) => (
                <li key={index}>{vio}</li>
            ));
            formGroupClsNotSelected += 'has-warning';
            formGroupClsSelected += 'has-warning';
        } else {
            const rules = Object.values(getPasswordRules());
            hintList = rules.map((rule, index) => <li key={index}>{rule}</li>);
        }

        const overlay =
            hintList.length > 0 ? (
                <Overlay
                    show={true}
                    target={this.refPasswordInputGroup.current}
                    container={this}
                    shouldUpdatePosition={true}
                >
                    <Popover id='popover-basic' className={prefixNS('popover')}>
                        <h4>{formatStr('onboarding_input_new_password')}</h4>
                        <ul>{hintList}</ul>
                    </Popover>
                </Overlay>
            ) : null;

        const showPasswordIcon = showPassword ? EyeOpenIcon : EyeCloseIcon;
        const showPasswordTitle = showPassword
            ? formatStr('onboarding_hide_password_icon')
            : formatStr('onboarding_show_password_icon');

        const passwordLabel =
            action === 'password_reset'
                ? formatStr('login_new_password_label')
                : formatStr('login_password_label');

        const component = (
            <OverlayTrigger trigger='focus' placement='right' overlay={overlay}>
                <InputGroup ref={this.refPasswordInputGroup}>
                    <FormControl
                        className={formControlCls}
                        inputRef={this.refPasswordInput}
                        value={password}
                        autoFocus={true}
                        onChange={this.handleChange}
                        type={showPassword ? 'input' : 'password'}
                        name='password'
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                    />
                    <InputGroup.Button>
                        <img
                            src={showPasswordIcon}
                            onClick={this.passwordShowHide}
                            title={showPasswordTitle}
                            alt={showPasswordTitle}
                            className={prefixNS('show-password-icon')}
                        />
                    </InputGroup.Button>
                </InputGroup>
            </OverlayTrigger>
        );

        const backButton =
            backURL && allowBack ? (
                <Button
                    onClick={this.handleBack}
                    buttonStyle='outline'
                    value={backButtonLabel}
                />
            ) : null;

        const controls = submitting ? (
            <Throbber />
        ) : (
            <div className={prefixNS('button-group')}>
                <Button
                    onClick={this.handleSubmit}
                    submit={true}
                    disabled={!valid}
                    value={formatStr('onboarding_continue_button')}
                />
                {backButton}
            </div>
        );

        return (
            <LoginBox>
                <form onSubmit={this.handleSubmit} className={prefixNS('form')}>
                    {this.props.messageBox}
                    {userCreationFailed && (
                        <Alert bsStyle={'danger'}>
                            <strong>
                                {formatStr('onboarding_user_creation_failed')}
                            </strong>
                        </Alert>
                    )}
                    <fieldset>
                        <FormGroup
                            className='field-wrapper'
                            controlId='username'
                        >
                            <ControlLabel>
                                {formatStr('onboarding_username')}
                            </ControlLabel>
                            <FormControl
                                readOnly={true}
                                type='text'
                                value={form.email}
                            />
                        </FormGroup>
                        <FormGroup
                            controlId='password'
                            className={
                                focused
                                    ? formGroupClsSelected
                                    : formGroupClsNotSelected
                            }
                        >
                            <ControlLabel>{passwordLabel}</ControlLabel>
                            {component}
                        </FormGroup>
                        <Notice labelName={'agreement_notice'} />
                        {controls}
                    </fieldset>
                </form>
            </LoginBox>
        );
    }
}

RegistrationPassword.propTypes = formPropTypes;
export default withForm(RegistrationPassword);
