/*
 * Copyright (C) 2019 - 2021 CONTACT Software GmbH
 * All rights reserved.
 * https://www.contact-software.com
 */

import React from 'react';
import cookies from 'browser-cookies';
import {
    prefixThemeNS,
    prefixNS,
    getLanguages,
    getCurrentLanguage,
} from '../helpers';
import {Dropdown, MenuItem} from 'react-bootstrap';

export default class LanguageSelector extends React.Component {
    constructor(props) {
        super(props);
        this.handleSelectLanguage = this.handleSelectLanguage.bind(this);
    }

    handleSelectLanguage(lang) {
        cookies.set('contact.language', lang);
        window.location.reload();
    }

    render() {
        const currentLanguage = getCurrentLanguage();
        const languages = getLanguages();
        const currentLanguageName = languages.find(
            (l) => l[1] === currentLanguage
        )[0];
        const className =
            prefixThemeNS('language-box') + ' ' + prefixNS('language-box');
        const selectedCls = 'cs-web-components-base-menu__item active';
        return (
            <Dropdown
                className={className}
                id='languageSelector'
                pullRight
                onSelect={this.handleSelectLanguage}
            >
                <Dropdown.Toggle>
                    <img src='/static/images/Globe.svg' />
                    <a>{currentLanguageName}</a>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {languages.map((language) => (
                        <MenuItem
                            key={language[1]}
                            eventKey={language[1]}
                            className={
                                currentLanguage === language[1]
                                    ? selectedCls
                                    : null
                            }
                        >
                            <span className={prefixNS('language-box-item')}>
                                {language[0]}
                            </span>
                        </MenuItem>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
