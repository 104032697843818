/*
 * Copyright (C) 2019 - 2021 CONTACT Software GmbH
 * All rights reserved.
 * https://www.contact-software.com
 */

import React from 'react';
import PropTypes from 'prop-types';
import {formatStr} from '../i18n';

function Notice({labelName}) {
    return <div dangerouslySetInnerHTML={{__html: formatStr(labelName)}} />;
}

Notice.propTypes = {
    labelName: PropTypes.string,
};

Notice.defaultProps = {
    labelName: 'agreement_notice',
};

export default Notice;
