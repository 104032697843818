/* -*- mode: web; coding: utf-8 -*-
 * Copyright (C) 2016 CONTACT Software GmbH
 * All rights reserved.
 * http://www.contact-software.com
 *
 * Revision "$Id: fetch.js 169743 2017-12-01 13:11:03Z mbr $"
 */

import qs from 'qs';
import {getConsole} from './helpers.js';

/**
 * Provide a set of wrapper functions to fetch data from the backend, or
 * send data to the backend. All functions return a Promise object, so callers
 * have a consistent way to attach follow-up actions to them.
 *
 * @module
 */

function checkStatus(response) {
    if (response.ok) {
        return response;
    } else {
        const error = new Error(response.statusText);
        error.response = response;
        getConsole().log('Error in response: ', error);
        throw error;
    }
}

const cookieDict = {};

function readDocumentCookie(name) {
    if (cookieDict[name]) {
        return cookieDict[name];
    }

    const re = new RegExp(name + '=([^;]+)');
    const value = re.exec(document.cookie);

    if (value) {
        cookieDict[name] = unescape(value[1]);
    }

    return cookieDict[name];
}

/**
 * Wrapper around the standard fetch API, that throws errors for all returned
 * status codes not in the 2xx range.
 * See https://github.com/github/fetch#handling-http-error-statuses
 * This is a low level function that has the same arguments as the standard (to
 * be ...) HTML5 fetch function.
 *
 * @param url {string}
 * @param init {object}
 * @return {thenable}
 */
export function fetchAndCheck(url, init = {}) {
    const options = Object.assign(init, {credentials: 'same-origin'});

    if (!options.headers) {
        options.headers = {};
    }

    /**
     * The cookie should always be present, otherwise the setup seems to
     * be broken. Therefore, even if there is no 'CSRFToken' cookie present,
     * we will set the HTTP Header anyway.
     */
    options.headers['X-Csrf-Token'] = readDocumentCookie('CSRFToken');

    if (url === undefined) {
        getConsole().log('Fetch error: URL is undefined.');
    }

    return fetch(url, options)
        .catch((error) => {
            getConsole().log('Request error: ', error);
            throw error.message;
        })
        .then(checkStatus);
}

export function urlWithParams(url, params) {
    if (params) {
        // Small trick to parse the url using the JS interpreter
        const a = document.createElement('a');
        a.href = url;

        let separator = '?';
        if (a.search) {
            separator = '&';
        } else if (url.endsWith('?')) {
            separator = '';
        }

        return `${url}${separator}${qs.stringify(params, {indices: false})}`;
    }

    return url;
}

/**
 * Issue a GET request, and interpret the result as JSON.
 *
 * @param {string} url - the URL to call. If needed, can already contain query parameters
 * @param {Object} [params] - optional query parameters to append to the URL
 * @return {thenable}
 */
export function getJSON(url, params) {
    return fetchAndCheck(urlWithParams(url, params)).then((response) =>
        response.json()
    );
}

function sendJSON(url, method, data) {
    const options = {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(data),
    };
    return fetchAndCheck(url, options).then((response) => {
        return response.text().then((text) => {
            return text
                ? Promise.resolve(JSON.parse(text))
                : Promise.resolve(text);
        });
    });
}

/**
 * Issue a POST request with JSON formatted request body, and interpret the
 * result as JSON, if a response body was received.
 *
 * @param {string} url - the URL to call
 * @param data - the request payload, will be formatted as JSON
 * @return {thenable}
 */
export function postJSON(url, data) {
    return sendJSON(url, 'POST', data);
}

/**
 * Issue a POST request with the payload formatted as form data, and interpret
 * the result as JSON.
 *
 * @param {string} url - the URL to call
 * @param {FormData} formData - the request payload
 * @return {thenable}
 */
export function postForm(url, formData) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: qs.stringify(formData),
    };
    return fetchAndCheck(url, options).then((response) => response.json());
}
