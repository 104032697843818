/*
 * Copyright (C) 2019 - 2021 CONTACT Software GmbH
 * All rights reserved.
 * https://www.contact-software.com
 */

export function formatStr(label) {
    return window.appSetup.messages === undefined ||
        window.appSetup.messages[label] === undefined
        ? label
        : window.appSetup.messages[label];
}
