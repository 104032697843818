/*
 * Copyright (C) 2019 - 2021 CONTACT Software GmbH
 * All rights reserved.
 * https://www.contact-software.com
 */

import React, {useCallback, useMemo, useState} from 'react';
import {
    ControlLabel,
    FormControl,
    FormGroup,
    Overlay,
    OverlayTrigger,
    Popover,
    Radio,
} from 'react-bootstrap';
import {getPhoneMethods, getPhoneNumberRequired, prefixNS} from '../helpers';
import {formatStr} from '../i18n';
import Notice from './Notice';
import PropTypes from 'prop-types';

export function PhoneInputForm({
    formClasses,
    inputClass,
    phoneNumber,
    setPhoneNumber,
    selectedMethod,
    selectMethod,
    disabled,
    showNotice,
}) {
    const requirePhoneNumber = getPhoneNumberRequired();
    const phoneMethods = getPhoneMethods();
    const phoneMethodKeys = useMemo(
        () => Object.keys(phoneMethods).sort(),
        [phoneMethods]
    );
    const multipleMethods = phoneMethodKeys.length > 1;
    const [phoneInputRef, setPhoneInputRef] = useState(null);

    const handleMethodChange = useCallback(
        (e) => {
            selectMethod(e.target.value);
        },
        [selectMethod]
    );

    const updatePhoneInputRef = useCallback(
        (node) => setPhoneInputRef(node),
        [setPhoneInputRef]
    );

    if (!requirePhoneNumber) {
        return null;
    }

    const phonePopoverMessage = formatStr(
        'onboarding_phone_number_announcement'
    );
    const phoneOverlay = (
        <Overlay show={true} target={phoneInputRef} shouldUpdatePosition={true}>
            <Popover id='popover-basic'>{phonePopoverMessage}</Popover>
        </Overlay>
    );

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    return (
        <React.Fragment>
            <FormGroup className={formClasses} controlId='email'>
                <ControlLabel>
                    {formatStr('onboarding_phone_number')}
                </ControlLabel>

                <OverlayTrigger
                    trigger='focus'
                    placement='right'
                    overlay={phoneOverlay}
                >
                    <FormControl
                        disabled={disabled}
                        className={inputClass}
                        inputRef={updatePhoneInputRef}
                        name='phone'
                        type='tel'
                        placeholder='+49 123 456789'
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                    />
                </OverlayTrigger>
            </FormGroup>
            {multipleMethods && (
                <FormGroup
                    className={`${formClasses} ${prefixNS(
                        'phone-method-group'
                    )}`}
                >
                    {phoneMethodKeys.map((key) => (
                        <Radio
                            onChange={handleMethodChange}
                            key={key}
                            value={key}
                            disabled={disabled}
                            name={'phone_mode'}
                            checked={selectedMethod === key}
                        >
                            {formatStr(phoneMethods[key])}
                        </Radio>
                    ))}
                </FormGroup>
            )}
            {showNotice && <Notice labelName={'phone_number_notice'} />}
        </React.Fragment>
    );
}

PhoneInputForm.propTypes = {
    disabled: PropTypes.bool,
    inputClass: PropTypes.string,
    formClasses: PropTypes.string,
    phoneNumber: PropTypes.string,
    setPhoneNumber: PropTypes.func,
    selectedMethod: PropTypes.string,
    selectMethod: PropTypes.func,
    showNotice: PropTypes.bool,
};

PhoneInputForm.defaultProps = {
    disabled: false,
    inputClass: '',
    formClasses: '',
    showNotice: true,
};
