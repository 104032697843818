/*
 * Copyright (C) 2019 - 2021 CONTACT Software GmbH
 * All rights reserved.
 * https://www.contact-software.com
 */

import React from 'react';
import LoginBox from './LoginBox';
import withForm, {formPropTypes} from './WithForm';
import {formatStr} from '../i18n';
import {
    ControlLabel,
    FormControl,
    FormGroup,
    Overlay,
    OverlayTrigger,
    Popover,
} from 'react-bootstrap';
import {
    getFormAction,
    getFormData,
    getHiddenValues,
    getPhoneMethod,
    getPhoneNumber,
    prefixNS,
} from '../helpers';
import Throbber from './Throbber';
import Button from './Button';
import refreshIcon from './cis_refresh.svg';
import {PhoneInputForm} from './PhoneInputForm';

const COOLDOWN = 60; // Resending cool down time in seconds

function SMSValidation({
    submitting,
    doSubmit,
    messageBox,
    retryPossible,
    retriggerPossible,
}) {
    const formClasses = `field-wrapper ${prefixNS('form-group')}`;
    const [code, setCode] = React.useState('');
    const [inputNode, setInputNode] = React.useState(null);
    const inputRefCallback = React.useCallback((node) => {
        setInputNode(node);
    }, []);

    const [phoneNumber, setPhoneNumber] = React.useState(getPhoneNumber());
    const [phoneMethod, setPhoneMethod] = React.useState(getPhoneMethod());

    const [coolingDown, setCoolingDown] = React.useState(0);

    const handleChange = (event) => {
        setCode(event.target.value);
    };

    const handleResendSMS = React.useCallback(
        (event) => {
            event.preventDefault();
            const formURL = getFormAction();
            const form = getFormData();
            const data = {
                resend_sms: true,
                phone_number: phoneNumber,
                phone_method: phoneMethod,
                confirmation_token: form.confirmation_token,
                ...getHiddenValues(),
            };
            setCoolingDown(COOLDOWN);
            doSubmit(formURL, data);
            inputNode.focus();
        },
        [doSubmit, inputNode, phoneNumber, phoneMethod]
    );

    const handleSubmit = React.useCallback(
        (event) => {
            event.preventDefault();
            const formURL = getFormAction();
            const form = getFormData();
            const data = {
                sms_token: code,
                confirmation_token: form.confirmation_token,
                ...getHiddenValues(),
            };

            doSubmit(formURL, data);
        },
        [code, doSubmit]
    );

    React.useEffect(() => {
        let timer;
        if (coolingDown > 0) {
            timer = window.setTimeout(() => {
                setCoolingDown(coolingDown - 1);
            }, 1000);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [coolingDown]);

    const disabled = retryPossible ? undefined : true;
    const disabledResend = retriggerPossible ? undefined : true;
    const controls = submitting ? (
        <Throbber />
    ) : (
        <div className={prefixNS('button-group')}>
            <Button
                onClick={handleSubmit}
                submit={true}
                disabled={code === ''}
                value={formatStr('onboarding_continue_button')}
            />
        </div>
    );

    const resendTitle =
        formatStr('onboarding_send_sms_again') +
        (coolingDown > 0
            ? formatStr('onboarding_send_sms_interval').replace(
                  '{sec}',
                  coolingDown
              )
            : '');

    const codePopoverMessage = formatStr('onboarding_sms_validation_started');
    const codeOverlay = (
        <Overlay show={true} target={inputNode} shouldUpdatePosition={true}>
            <Popover id='popover-basic'>{codePopoverMessage}</Popover>
        </Overlay>
    );

    return (
        <LoginBox>
            <form onSubmit={handleSubmit} className={prefixNS('form')}>
                {messageBox}
                <fieldset>
                    <PhoneInputForm
                        disabled={disabled}
                        setPhoneNumber={setPhoneNumber}
                        phoneNumber={phoneNumber}
                        selectedMethod={phoneMethod}
                        selectMethod={setPhoneMethod}
                        formClasses={formClasses}
                        showNotice={false}
                    />
                    <FormGroup className={formClasses}>
                        <ControlLabel>
                            {formatStr('onboarding_sms_code')}
                        </ControlLabel>
                        <OverlayTrigger
                            trigger='focus'
                            placement='right'
                            overlay={codeOverlay}
                        >
                            <FormControl
                                autoFocus
                                name='sms_code'
                                type='text'
                                placeholder={formatStr(
                                    'onboarding_sms_code_placeholder'
                                )}
                                value={code}
                                onChange={handleChange}
                                inputRef={inputRefCallback}
                                disabled={submitting}
                            />
                        </OverlayTrigger>
                    </FormGroup>
                    <Button
                        onClick={handleResendSMS}
                        buttonStyle={'auxiliary'}
                        classNames={[
                            prefixNS('narrow-button'),
                            prefixNS('margin-bottom'),
                            prefixNS('resend_sms'),
                        ]}
                        value={resendTitle}
                        iconSrc={refreshIcon}
                        disabled={coolingDown > 0 || disabledResend}
                    />
                    {controls}
                </fieldset>
            </form>
        </LoginBox>
    );
}

SMSValidation.propTypes = formPropTypes;
export default withForm(SMSValidation);
