/*
 * Copyright (C) 2019 - 2021 CONTACT Software GmbH
 * All rights reserved.
 * https://www.contact-software.com
 */

export function prefixNS(name) {
    return `${componentNameSpace}-${name}`;
}

export function prefixThemeNS(name) {
    return `cs-web-components-theme-static-${name}`;
}

export function initAppSetup() {
    window.appSetup = JSON.parse(
        atob(document.getElementById('appSetupValue').value)
    );
}

export function getLogo() {
    return (
        window.appSetup.logo_url || '/static/imgid/branding_web_portal_logo.svg'
    );
}

export function getAppComponentName() {
    return window.appSetup.componentName || 'Login';
}

export function getPasswordRules() {
    return window.appSetup.rules || {};
}

export function getHiddenValues() {
    return window.appSetup.hidden_values || {};
}

export function getAllowBack() {
    return window.appSetup.allow_back || false;
}

export function getFormAction() {
    return window.appSetup.post_action || null;
}

export function getRegistrationURL() {
    return window.appSetup.registrationURL || '';
}

export function getLanguages() {
    return window.appSetup.languages || null;
}

export function getCurrentLanguage() {
    return window.appSetup.current_lang || null;
}

export function getLastUsername() {
    return window.appSetup.last_username || '';
}

export function getRememberMe() {
    // (window.appSetup.remember_me || true) would always be true
    if (Object.prototype.hasOwnProperty.call(window.appSetup, 'remember_me')) {
        return window.appSetup.remember_me;
    }
    return true;
}

export function getDebugMode() {
    return window.appSetup.debugMode || false;
}

const onlyInDebug = (fn) => (getDebugMode() ? fn : () => {});

/**
 * Wrapper around environments console object
 */
export function getConsole() {
    return {
        log: onlyInDebug(console.log.bind(window.console)), // eslint-disable-line
        warn: onlyInDebug(console.warn.bind(window.console)), // eslint-disable-line
        error: onlyInDebug(console.error.bind(window.console)), // eslint-disable-line
    };
}

export function getReturnTo() {
    return window.appSetup.returnTo || '';
}

export function getFormData() {
    return window.appSetup.formData || {};
}

export function getPasswordResetURL() {
    return window.appSetup.passwordResetURL || '';
}

export function getEmailToRegister() {
    return window.appSetup.email_to_register
        ? atob(window.appSetup.email_to_register)
        : '';
}

export function getPhoneNumberRequired() {
    return window.appSetup.require_phone || false;
}

export function getPhoneMethods() {
    return window.appSetup.phone_methods || {};
}

export function getPhoneNumber() {
    return window.appSetup.phone_number || '';
}

export function getPhoneMethod() {
    return window.appSetup.phone_method || '';
}

export function getComponentInitValue() {
    return window.appSetup.componentInitValue || {};
}
