/*
 * Copyright (C) 2019 - 2021 CONTACT Software GmbH
 * All rights reserved.
 * https://www.contact-software.com
 */

// Polyfills to add missing functionality to the global namespace
import 'babel-polyfill';
import 'whatwg-fetch';

import run from './run';

// run the application
document.addEventListener('DOMContentLoaded', run);

export default {};
