/*
 * Copyright (C) 2019 - 2021 CONTACT Software GmbH
 * All rights reserved.
 * https://www.contact-software.com
 */

import React from 'react';
import {prefixThemeNS} from '../helpers';
import PropTypes from 'prop-types';

class Button extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            submit,
            buttonStyle,
            value,
            disabled,
            onClick,
            classNames,
            iconSrc,
        } = this.props;

        const className =
            prefixThemeNS('submit') +
            ' btn ' +
            `cs-web-components-base-semantic-button-${buttonStyle} ` +
            classNames.join(' ');
        const type = submit ? 'submit' : 'button';

        return (
            <div className={prefixThemeNS('submit-box')}>
                {iconSrc ? <img src={iconSrc}></img> : null}
                <input
                    className={className}
                    type={type}
                    value={value}
                    disabled={disabled}
                    onClick={onClick}
                />
            </div>
        );
    }
}

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    submit: PropTypes.bool,
    value: PropTypes.string,
    buttonStyle: PropTypes.string,
    classNames: PropTypes.array,
    iconSrc: PropTypes.string,
};

Button.defaultProps = {
    disabled: false,
    submit: false,
    buttonStyle: 'primary',
    classNames: [],
};

export default Button;
